import { Descriptions } from 'antd';

import { toPhoneNumberFormat } from '@helper/formatHelper';

import useConsultationStore from '@store/useConsultationStore';

import CopyableText from '@components/common/CopyableText';
import MemberDetail from './MemberDetail';
import ConsultationButtonPanel from './ConsultationButtonPanel';

/**
 * 회원 상담 내역 상세 화면
 */
function DescriptionMember() {
  const currentMember = useConsultationStore((state) => state.currentMember);

  return (
    <div>
      <Descriptions
        bordered
        title={
          <>
            회원정보
            <ConsultationButtonPanel isMember />
          </>
        }
        column={2}
        className="mb-10"
        size="middle"
      >
        <Descriptions.Item labelStyle={{ width: 'fit' }} label="회원구분">
          <span className="text-secondary font-semibold">
            {`${currentMember?.displayTypeName || '-'}`}
          </span>
        </Descriptions.Item>
        <Descriptions.Item labelStyle={{ width: 'fit' }} label="회원번호">
          {currentMember?.memberId}
        </Descriptions.Item>
        <Descriptions.Item labelStyle={{ width: 'fit' }} label="전화번호">
          <CopyableText text={toPhoneNumberFormat(currentMember?.mobile || '-')} />
        </Descriptions.Item>
        <Descriptions.Item labelStyle={{ width: 'fit' }} label="이름">
          {currentMember?.memberName}
        </Descriptions.Item>
      </Descriptions>

      <MemberDetail currentMember={currentMember} />
    </div>
  );
}

export default DescriptionMember;
