import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { shallow } from 'zustand/shallow';

import { useLogin, useToken } from '@api/account';
import useAccountStore, { useAccountActions, useUserRolesStore } from '@store/useAccountStore';
import storage from '@utils/Storage';
import { connectSockets, disconnectSockets } from '@feature/cti/utils/Socket';
import { STORAGE_KEY_FOR_WORKPLACE } from '@constants/workplace';
import { callSocketActions } from '@feature/cti/actions/callSocketActions';

const AuthContext = createContext();

function AuthProvider({ children }) {
  const navigate = useNavigate();

  const [isSendToken, setSendToken] = useState(false);

  const requestLogin = useLogin();
  const requestToken = useToken();

  // store 저장
  const { fetchAccount } = useAccountActions();
  const [clear, agentNumber] = useAccountStore(
    (state) => [state.clear, state.agentNumber],
    shallow,
  );
  const [clearUserRoles] = useUserRolesStore((state) => [state.clearUserRoles]);

  const sendToken = ({ userId, userPw }) => {
    requestToken({ userId, userPw }, { onSuccess: () => setSendToken(true) });
  };

  const login = useCallback(
    (formValue) => {
      requestLogin(formValue, {
        onSuccess: () => {
          localStorage.setItem(STORAGE_KEY_FOR_WORKPLACE, formValue.workplace);
          fetchAccount();
          connectSockets();

          navigate('/consultation');
          // 로그인 페이지 원래대로
          setSendToken(false);
        },
      });
    },
    [fetchAccount, navigate, requestLogin],
  );

  const logout = useCallback(() => {
    storage.clearToken();
    callSocketActions.agentLogout();
    callSocketActions.qLogout();
    disconnectSockets();
    clear();
    clearUserRoles();
    navigate('/login');
  }, [navigate, clear, clearUserRoles]);

  useEffect(() => {
    if (storage.hasSession() && agentNumber === '') {
      fetchAccount();
      connectSockets();
    }
  }, [agentNumber, fetchAccount]);

  return (
    <AuthContext.Provider value={{ login, logout, isSendToken, sendToken }}>
      {children}
    </AuthContext.Provider>
  );
}

function useAuthContext() {
  return useContext(AuthContext);
}

export { AuthProvider, useAuthContext };
