import { Modal, Button, Descriptions } from 'antd';

import { useOverlayContext } from '@context/OverlayContext';

import { toPhoneNumberFormat } from '@helper/formatHelper';
import { useLocation, useNavigate } from 'react-router-dom';
import { handleOutboundCall } from '@feature/cti/actions/outboundCallActions';
import { useCallerContext } from '@context/CallerContext';
import CopyableText from '@components/common/CopyableText';
import ModalContainer from './base/ModalContainer';

/**
 * 상담 내역 > 전화걸기 버튼 클릭 시 나타나는 전화걸기 모달
 */
function ModalOutCall({ customerPhone, customerName, customerId }) {
  const { closeModal } = useOverlayContext();
  const navigate = useNavigate();
  const location = useLocation();
  const { isOnCall } = useCallerContext();

  const handleClose = () => {
    closeModal();
  };

  const handleOutCall = () => {
    if (!customerPhone) return;
    handleOutboundCall({
      customerPhone,
      customerId,
      currentPath: location.pathname,
      navigate,
      onComplete: handleClose,
    });
  };

  return (
    <ModalContainer>
      <Modal
        open
        title="전화걸기"
        closable={false}
        footer={
          <>
            <Button type="primary" onClick={handleOutCall} disabled={isOnCall || !customerPhone}>
              전화걸기
            </Button>
            <Button onClick={() => handleClose()}>닫기</Button>
          </>
        }
        width="40%"
      >
        <Descriptions bordered className="my-5">
          {customerName && (
            <Descriptions.Item label="이름" labelStyle={{ width: '20%' }} span={3}>
              <div className="w-fit">{customerName}</div>
            </Descriptions.Item>
          )}
          <Descriptions.Item label="전화번호" labelStyle={{ width: '20%' }} span={3}>
            <CopyableText text={toPhoneNumberFormat(customerPhone)} />
          </Descriptions.Item>
        </Descriptions>
      </Modal>
    </ModalContainer>
  );
}

export default ModalOutCall;
