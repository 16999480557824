import { api } from '@utils/Api';

import { useMutation } from '@tanstack/react-query';
import { useOverlayContext } from '@context/OverlayContext';
import { useCallSocketStore } from '@feature/cti/store/useCallSocketStore';
import { shallow } from 'zustand/shallow';
import { useCallback, useEffect, useRef } from 'react';
import { CALL_TYPE } from '@constants/consultation';

export const usePatchConsultRecordSave = () => {
  const { openAlert } = useOverlayContext();

  const [call] = useCallSocketStore((state) => [state.call], shallow);
  const callRef = useRef(call);

  // 리렌더링 방지로 useRef 사용
  useEffect(() => {
    callRef.current = { ...call };
  }, [call]);

  const { mutateAsync: mutateRecordSave } = useMutation({
    mutationFn: async (record) => {
      const payload = {
        agentNumber: Number(record.agentNumber),
        customerPhone: record.customerPhoneNumber,
        cid: record.cid,
        callType: Object.values(CALL_TYPE).find((type) => type.EVENT === record.eventType)?.VALUE,
        fileKey: record.file,
        ivrPath: record.ivr,
      };

      await api.post(`external/v1/record-histories`, payload);
    },
  });

  const { mutateAsync: mutateRecordEnd } = useMutation({
    mutationFn: async (record) => {
      await api.post(`external/v1/record-histories/end`, {
        fileKey: record.file,
      });
    },
    retry: false,
    onError: () => {
      openAlert('녹취 파일을 저장하지 못했습니다.');
    },
  });

  /**
   * 상담녹취 등록 및 수정 > 상담녹취 시작
   */
  const requestConsultRecordSave = useCallback(async () => {
    if (!callRef.current) return;
    const record = { ...callRef.current };
    try {
      await mutateRecordSave(record);
    } catch (error) {
      openAlert('녹취 파일을 저장할 수 없습니다.');
    }
  }, [mutateRecordSave, openAlert]);

  /**
   * 상담녹취 등록 및 수정 > 상담녹취 종료
   */
  const requestConsultRecordEnd = useCallback(async () => {
    if (!callRef.current || !callRef.current.file) return;
    const record = { ...callRef.current };
    await mutateRecordEnd(record);
  }, [mutateRecordEnd]);

  return { requestConsultRecordSave, requestConsultRecordEnd };
};

/**
 * Agent 파일 API > 녹음(녹취) 파일 다운로드
 */
export const useGetAgentFile = () => {
  const { mutateAsync: getRecordFile } = useMutation({
    mutationFn: (fileKey) => api.get(`/external/v1/agent-files/${fileKey}/downloads`),
  });

  return { getRecordFile };
};
