import axios from 'axios';
import { parse, stringify } from 'qs';
import { isEmpty } from 'lodash';

import storage from './Storage';

export const HttpMethod = {
  GET: 'GET',
  HEAD: 'HEAD',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
  CONNECT: 'CONNECT',
  OPTIONS: 'OPTIONS',
  PATCH: 'PATCH',
};

export const StatusCode = {
  Ok: '200',
  Created: '201',
  Unauthorized: '401',
  Forbidden: '403',
  PageNotFound: '404',
  Authorized: '426',
  TooManyRequests: '429',
  InternalServerError: '500',
};

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json; charset=utf-8',
  'X-Requested-With': 'XMLHttpRequest',
};

const injectRequestFulfilled = (config) => {
  try {
    const { accessToken, refreshToken } = storage.getToken();
    if (!isEmpty(accessToken) && !isEmpty(refreshToken)) {
      if (!config.headers) config.headers = {};
      config.headers.authorization = `Bearer ${accessToken}` || '';
      config.headers.X_CATS_RT = refreshToken || '';
    }
    return config;
  } catch (error) {
    throw new Error(error);
  }
};

const injectRequestRejected = (error) => Promise.reject(error);

const injectResponseFulfilled = (response) => {
  // 파일 다운로드의 경우
  if (response.config.headers.Accept === 'application/octet-stream') {
    return response.data;
  }

  // login / CAS API의 경우
  /**
   * 요청 보내는 url에 login, send-talk, token이 포함되어 있거나
   * baseURL에 cas-admin이 포함되어 있으면 CAS API를 그대로 활용하고 있는 상황
   * 따라서 status, message, result 등의 응답 데이터가 아닌
   * resultCd, resultMsg, resultData 등의 응답 데이터를 활용하도록 별도 처리
   */
  if (
    response.request?.responseURL.includes('login') ||
    response.request?.responseURL.includes('send-talk') ||
    // response.request?.responseURL.includes('token') ||
    response.config.baseURL.includes('cas-admin')
  ) {
    const { resultCd, resultMsg, resultData } = response.data;

    if (resultCd !== StatusCode.Ok && resultCd !== StatusCode.Created) {
      return Promise.reject(resultMsg);
    }

    return resultData;
  }

  // 일반적인 경우 (LOGI, HELP-DESK)
  const { message, status, result } = response.data;
  const newToken = response.headers.authorization;
  const { accessToken, refreshToken } = storage.getToken();

  // 토큰 업데이트
  if (newToken && newToken !== `Bearer ${accessToken}`) {
    storage.setToken(newToken.replace('Bearer ', ''), refreshToken);
  }

  if (status !== 'OK') {
    return Promise.reject(message);
  }

  return result;
};

const injectResponseRejected = (instance) => (error) => {
  const originalRequest = error.config;
  // CAS 용
  if (originalRequest.baseURL.includes('cas-admin')) {
    const { resultCd, resultData } = error.response.data;

    if (resultCd === StatusCode.Unauthorized) {
      storage.clearToken();

      if (window.location.pathname !== '/login') {
        window.alert('미인증 상태입니다. 로그인 페이지로 이동합니다.');
        window.location = '/login';
      }
    } else if (resultCd === StatusCode.Authorized) {
      const { accessToken, refreshToken } = resultData;
      storage.setToken(accessToken, refreshToken);

      return instance(originalRequest);
    }

    return Promise.reject(error);
  }

  // LOGI, HELP-DESK
  const { status, data } = error.response;
  if (status === Number(StatusCode.Unauthorized)) {
    storage.clearToken();

    if (window.location.pathname !== '/login') {
      window.alert('미인증 상태입니다. 로그인 페이지로 이동합니다.');
      window.location = '/login';
    }
  } else if (status === Number(StatusCode.Authorized)) {
    // TODO: 토큰 재발급 기능 확인
    const { accessToken, refreshToken } = data?.resultData || {};
    storage.setToken(accessToken.replace('Bearer ', ''), refreshToken);

    return instance(originalRequest);
  }
  return Promise.reject(error);
};

class Api {
  _instance = null;

  get ajax() {
    return this._instance != null ? this._instance : this.initHttp();
  }

  initHttp(baseURL = `${process.env.REACT_APP_HELP_DESK_API_URL}`) {
    const ajax = axios.create({
      baseURL,
      headers,
      paramsSerializer: {
        encode: parse,
        serialize: stringify,
      },
    });

    ajax.interceptors.request.use(injectRequestFulfilled, injectRequestRejected);

    ajax.interceptors.response.use(injectResponseFulfilled, injectResponseRejected(ajax));

    this._instance = ajax;
    return ajax;
  }
}

export const api = new Api().initHttp();

export const logiApi = new Api().initHttp(process.env.REACT_APP_LOGI_API_URL);
export const casApi = new Api().initHttp(process.env.REACT_APP_CAS_API_URL);
