import { Dropdown, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import Button from 'antd/lib/button';
import { shallow } from 'zustand/shallow';

import { useCallSocketStore } from './store/useCallSocketStore';
import { catsEventAgentSelector, useMonitorSocketStore } from './store/useMonitorSocketStore';
import { AGENT_SOCKET_STATUS, CALL_EVENT, DEVICE_STATUS, QUEUE_REASON } from './data/constants';
import { callSocketActions } from './actions/callSocketActions';
import PickupCallButton from './components/PickupCallButton';

/**
 * CATS-10198 이석, 돌려주기, 당겨받기 버튼 비활성화 처리 (작업 및 검증 범위 제외)
 */
function TransferDropdownButton({ shouldDisable, button, ...rest }) {
  const { menuItems, onClick, label } = button;

  return (
    <Dropdown
      // disabled={shouldDisable}
      disabled
      menu={{
        items: menuItems || [{ label: 'a', key: '1' }],
        onClick,
      }}
      {...rest}
    >
      <Button>
        <Space>
          {label}
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
}

// CTI 기능 연동 버튼 패널 (통화대기, 전화받기, 돌려주기 등)
function CallerPanel() {
  const [queue, deviceStatus, latestAgentCallEvent] = useCallSocketStore(
    (state) => [state.queue, state.device.status, state.latestAgentCallEvent],
    shallow,
  );
  const { status: queueStatus, connected: queueConnected } = queue;

  const agents = useMonitorSocketStore(catsEventAgentSelector);
  const getTransferMenus = () => Object.keys(agents).map((key) => ({ label: key, key }));
  const isPausedStatus = () =>
    queueStatus === AGENT_SOCKET_STATUS.PAUSE ||
    queueStatus === AGENT_SOCKET_STATUS.AFTER ||
    queueStatus === AGENT_SOCKET_STATUS.LEAVE ||
    queueStatus === AGENT_SOCKET_STATUS.AT_TABLE;

  const BUTTONS = [
    {
      type: queueStatus === AGENT_SOCKET_STATUS.ACTIVATE ? 'primary' : 'default',
      onClick: callSocketActions.qUnpause,
      label: '통화대기',
    },
    {
      type: isPausedStatus() ? 'primary' : 'default',
      onClick: () => callSocketActions.qPause(QUEUE_REASON.PAUSE.value),
      label: '수신거부',
    },
    {
      type: queueStatus === AGENT_SOCKET_STATUS.AFTER ? 'primary' : 'default',
      onClick: () => callSocketActions.qPause(QUEUE_REASON.AFTER.value),
      label: '문서',
    },

    ...(deviceStatus === DEVICE_STATUS.INUSE
      ? [
          {
            type: 'primary',
            onClick: callSocketActions.hangupCall,
            label: '전화끊기',
          },
        ]
      : []),
    ...(deviceStatus !== DEVICE_STATUS.INUSE
      ? [
          {
            render: () => (
              <PickupCallButton
                key="pickup-call-button"
                type={latestAgentCallEvent === CALL_EVENT.RINGING ? 'primary' : 'default'}
                shouldDisable={!queueConnected || isPausedStatus()}
                className="mr-[10px]"
              />
            ),
          },
        ]
      : []),
    {
      type: queueStatus === AGENT_SOCKET_STATUS.LEAVE ? 'primary' : 'default',
      onClick: () => callSocketActions.qPause(QUEUE_REASON.LEAVE.value),
      label: '이석',
      // shouldDisable: deviceStatus === DEVICE_STATUS.INUSE,
      shouldDisable: true,
    },
    {
      label: '돌려주기',
      menuItems: getTransferMenus(),
      onClick: ({ key: receiver }) => {
        callSocketActions.transfer(receiver);
      },
      dropdown: true,
      // shouldDisable: deviceStatus === DEVICE_STATUS.INUSE,
      shouldDisable: true,
    },
    {
      label: '돌려주기 취소',
      onClick: callSocketActions.unTransfer,
      // shouldDisable: deviceStatus === DEVICE_STATUS.NOT_INUSE,
      shouldDisable: true,
    },
    {
      label: '당겨받기',
      onClick: () => {
        callSocketActions.qPause();
        callSocketActions.pickupTransfer();
      },
      // shouldDisable: deviceStatus === DEVICE_STATUS.NOT_INUSE,
      shouldDisable: true,
    },
    {
      type: deviceStatus === DEVICE_STATUS.INUSE ? 'primary' : 'default',
      label: '욕설고객ARS',
      onClick: () => callSocketActions.playIVRScenario('protect_hangup'),
      // shouldDisable: deviceStatus !== DEVICE_STATUS.INUSE,
      shouldDisable: true,
      ghost: deviceStatus === DEVICE_STATUS.INUSE,
    },
  ];

  return (
    <div>
      {BUTTONS.map((button) => {
        const { render, type, onClick, label, shouldDisable, dropdown, ghost } = button;
        if (render) return render();
        if (dropdown)
          return (
            <TransferDropdownButton
              className="mr-[10px]"
              key="caller-group-dropdown"
              button={button}
            />
          );

        return (
          <Button
            className="mr-[10px]"
            type={type || 'default'}
            onClick={onClick}
            disabled={!queueConnected || !!shouldDisable}
            key={label}
            ghost={ghost}
          >
            {label}
          </Button>
        );
      })}
    </div>
  );
}

export default CallerPanel;
