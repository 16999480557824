import { useCallback } from 'react';
import { callerSocket } from '@feature/cti/utils/Socket';
import { useCallSocketStore } from '../store/useCallSocketStore';
import { parseSocketEventResponse, parseSocketResponse } from '../messages/callMessage';

function useCallSocket() {
  const setCallSocketState = useCallSocketStore((state) => state.setCallSocketState);
  const initCallSocket = useCallback(() => {
    if (!callerSocket) return Promise.reject(new Error('소켓에 연결되어 있지 않음'));

    callerSocket
      .on('RESPONSE', ({ MESSAGE }) => {
        console.log('RESPONSE MESSAGE : ', MESSAGE);
        return setCallSocketState([
          (state) => parseSocketResponse(state, { MESSAGE }),
          false,
          `@@SOCKET_RESPONSE : ${MESSAGE.CODE}`,
        ]);
      })
      .on('EVENT', ({ MESSAGE }) =>
        setCallSocketState([
          (state) => parseSocketEventResponse(state, { MESSAGE }),
          false,
          `@@SOCKET_EVENT : ${MESSAGE.CODE}`,
        ]),
      );
    return Promise.resolve();
  }, [setCallSocketState]);

  return {
    initCallSocket,
  };
}
export default useCallSocket;
