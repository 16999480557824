import { create } from 'zustand';
import produce from 'immer';
import { MEMBER_TYPE_CD } from '@constants/consultation';

/**
 * 1. originMembers: 검색 조건에 맞는 모든 회원 목록을 가져옴
 * 2. 이 목록에서 유효한 회원을 필터링 (학생이 아닌 회원) -> memberData
 * 3.필터링된 회원의 memberId로 memberDetail API를 호출해서 상세 정보를 가져옴 -> currentMember
 */
const initialValue = {
  currentMember: {},
  memberData: {},
  nonMemberOrOnlyStudent: false, // 비회원이거나 학생만 있는 경우
};

const useConsultationStore = create((set) => ({
  ...initialValue,
  clear: () => set(initialValue),
  setMemberData: (members) =>
    set(
      produce((state) => {
        state.memberData =
          members?.list?.filter(
            (member) =>
              !member.memberTypes.some((type) => type.memberTypeCd === MEMBER_TYPE_CD.STUDENT),
          )[0] || [];
      }),
    ),
  setCurrentMember: (member) =>
    set(
      produce((state) => {
        state.currentMember = member;
      }),
    ),
  setNonMemberOrOnlyStudent: (isNonMemberOrOnlyStudent) =>
    set(
      produce((state) => {
        state.nonMemberOrOnlyStudent = isNonMemberOrOnlyStudent;
      }),
    ),
}));

export default useConsultationStore;
