import { ContactsOutlined, UserOutlined } from '@ant-design/icons';
import { ConsultationPage, ConsultationPageDeail, ReservationPage } from '@pages';

const workConfig = [
  {
    path: 'consultation',
    element: ConsultationPage,
    option: {
      parent: '업무',
      label: '상담 내역',
      icon: <UserOutlined />,
      key: 'consultation',
    },
  },
  {
    path: 'consultation/:key',
    element: ConsultationPageDeail,
    option: {
      label: '상담 상세',
    },
  },
  {
    path: 'reservation',
    element: ReservationPage,
    option: {
      parent: '업무',
      label: '상담 예약',
      icon: <ContactsOutlined />,
      key: 'reservation',
    },
  },
  // {
  //   path: 'happyletter',
  //   element: HappyLetterPage,
  //   option: {
  //     parent: '업무',
  //     label: '해피문자',
  //     icon: <CommentOutlined />,
  //     key: 'happyletter',
  //   },
  // },
];

export default workConfig;
