import { useCallback, useMemo, useRef, useState } from 'react';
import { useUnmount } from 'ahooks';

function useTimer(validityTimeout) {
  const time = useRef(validityTimeout);
  const counter = useRef(null);

  const [timer, setTimer] = useState(0);
  const [isTimeout, setTimeout] = useState(false);

  const cleanUp = useCallback(() => {
    if (counter.current) {
      clearInterval(counter.current);
      counter.current = null;
    }

    time.current = validityTimeout;

    setTimer(0);
    setTimeout(false);
  }, [validityTimeout]);

  const endTimer = useCallback(() => {
    if (counter.current) {
      clearInterval(counter.current);
      counter.current = null;

      setTimeout(false);
    }
  }, []);

  const decreaseNum = useCallback(() => {
    // 1초씩 감소
    time.current -= 1;

    setTimer(() => time.current);
    if (time.current <= 0) {
      endTimer();
      setTimeout(true);
    }
  }, [endTimer]);

  const startTimer = useCallback(() => {
    counter.current = setInterval(decreaseNum, 1000);
    setTimeout(false);
  }, [decreaseNum]);

  const isWaiting = useMemo(() => !isTimeout && timer !== 0, [isTimeout, timer]);

  useUnmount(() => {
    cleanUp();
  });

  return {
    timer,
    isTimeout,
    isWaiting,
    startTimer,
    endTimer,
    cleanUp,
  };
}

export default useTimer;
