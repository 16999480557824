const CALL_TYPE = Object.freeze({
  INBOUND: {
    LABEL: '수신',
    VALUE: 'INBOUND',
    EVENT: 'I',
  },
  OUTBOUND: {
    LABEL: '발신',
    VALUE: 'OUTBOUND',
    EVENT: 'O',
  },
});

const MEMBER_TYPE_CD = Object.freeze({
  TEACHER: 'CD10001',
  STUDENT: 'CD10002',
  PARENTS: 'CD10003',
});

// 공감센터 관리자 권한. 녹취 파일 조회 및 다운로드 가능
const ROLE_HELP_MASTER = 'HELP_MASTER';

// 오늘의 통화목록 표 Pagination 기본 페이지 사이즈
const TODAY_CALL_TABLE_PAGE_SIZE = 5;

export { CALL_TYPE, MEMBER_TYPE_CD, ROLE_HELP_MASTER, TODAY_CALL_TABLE_PAGE_SIZE };
