import { EMIT_CMD, QUEUE_REASON } from '../data/constants';
import { callerSocket } from '../utils/Socket';

const log = (msg = '') => console.log(`  %cACTION:: %c${msg}`, 'color: orange', 'color: gold');

export const callSocketActions = {
  // 로그인
  agentLogin: (agentCode) => {
    log('@@SOCKET_EMIT: agentLogin');
    if (callerSocket)
      callerSocket.emit('ACTION', {
        CMD: EMIT_CMD.AGENT_LOGIN,
        AGENT_CODE: agentCode,
      });
  },

  // 로그아웃
  agentLogout: () => {
    log('@@SOCKET_EMIT: agentLogout');
    if (callerSocket) callerSocket.emit('ACTION', { CMD: EMIT_CMD.AGENT_LOGOUT });
  },

  // 큐 로그인 - 수신대기 전 상태
  qLogin: () => {
    log('@@SOCKET_EMIT: qLogin');
    if (callerSocket) callerSocket.emit('ACTION', { CMD: EMIT_CMD.QUEUE_LOGIN });
  },

  // 큐 로그아웃
  qLogout: () => {
    log('@@SOCKET_EMIT: qLogout');
    if (callerSocket) callerSocket.emit('ACTION', { CMD: EMIT_CMD.QUEUE_LOGOUT });
  },

  qPause: (reason) => {
    log('@@SOCKET_EMIT: qPause');
    if (callerSocket)
      callerSocket.emit('ACTION', {
        CMD: EMIT_CMD.QUEUE_READY,
        PAUSED: 'N',
        REASON: reason ?? QUEUE_REASON.PAUSE.value,
      });
  },

  // 수신대기 - 전화 오면 받을 수 있음
  qUnpause: () => {
    log('@@SOCKET_EMIT: qUnpause');
    if (callerSocket)
      callerSocket.emit('ACTION', {
        CMD: EMIT_CMD.QUEUE_READY,
        PAUSED: 'Y',
        REASON: '0',
      });
  },

  // 전화 받기
  answerCall: () => {
    log('@@SOCKET_EMIT: answerCall');
    if (callerSocket) callerSocket.emit('ACTION', { CMD: EMIT_CMD.ANSWER });
  },

  // 전화 끊기
  hangupCall: () => {
    log('@@SOCKET_EMIT: hangupCall');
    if (callerSocket) callerSocket.emit('ACTION', { CMD: EMIT_CMD.HANGUP });
  },

  // 전화 걸기
  outCall: (telNumber) => {
    log('@@SOCKET_EMIT: outCall');
    if (callerSocket)
      callerSocket.emit('ACTION', {
        CMD: EMIT_CMD.OUTCALL,
        TEL_NUMBER: telNumber,
      });
  },

  // 전화 돌려주기(거의 안씀)
  transfer: (receiver) => {
    log('@@SOCKET_EMIT: transfer');
    if (callerSocket)
      callerSocket.emit('ACTION', {
        CMD: EMIT_CMD.TRANSFER,
        RECEIVER: receiver,
      });
  },

  // 전화 돌려주기 취소
  unTransfer: () => {
    log('@@SOCKET_EMIT: unTransfer');
    if (callerSocket) callerSocket.emit('ACTION', { CMD: EMIT_CMD.UNTRANSFER });
  },

  // 당겨 받기
  pickupTransfer: () => {
    log('@@SOCKET_EMIT: pickupTransfer');
    if (callerSocket) callerSocket.emit('ACTION', { CMD: EMIT_CMD.PICKUP });
  },

  // IVR 시나리오 송출 (통화종료됨) - 파악 필요
  playIVRScenario: (flow) => {
    log('@@SOCKET_EMIT: playIVRScenario');
    if (callerSocket) callerSocket.emit('ACTION', { CMD: EMIT_CMD.IVR_ROUTING, FLOW: flow });
  },
};
