import { Button, message } from 'antd';
import { shallow } from 'zustand/shallow';
import { useNavigate } from 'react-router-dom';
import { CALL_TYPE } from '@constants/consultation';
import { callSocketActions } from '../actions/callSocketActions';
import { CALL_EVENT } from '../data/constants';
import { useCallSocketStore } from '../store/useCallSocketStore';

function PickupCallButton({ type, onPickup = () => {}, shouldDisable, ...rest }) {
  const [call, latestAgentCallEvent] = useCallSocketStore(
    (state) => [state.call, state.latestAgentCallEvent],
    shallow,
  );
  const navigate = useNavigate();

  // const { setCallPickedUp } = useSocketSideEffectActions();
  const onClickConnect = () => {
    if (!call?.customerPhoneNumber) {
      return message.warning('전화연결 대상을 선택하세요.');
    }

    if (latestAgentCallEvent === CALL_EVENT.RINGING || call.eventType === CALL_TYPE.INBOUND.EVENT) {
      callSocketActions.qPause();
      onPickup();
      navigate(`/consultation/${call.customerPhoneNumber}`);

      return callSocketActions.answerCall();
    }

    if (call.eventType === CALL_TYPE.OUTBOUND.EVENT) {
      // TODO: change screen
      // setCallPickedUp(true);
      callSocketActions.qPause();
      // socketActions.outCall(telNumber.replace(/\-/g, ''));
    }
  };

  return (
    <Button type={type} onClick={onClickConnect} disabled={shouldDisable} {...rest}>
      전화받기
    </Button>
  );
}

export default PickupCallButton;
