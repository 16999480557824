import { useState } from 'react';
import { Tooltip, Row, Space, Button } from 'antd';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';

import '@assets/css/ui.search.css';

function SearchItem({ children, className = 'flex-1' }) {
  return <div className={className}>{children}</div>;
}

function SearchTitle({ hidden, onChange }) {
  return (
    <Tooltip title={hidden ? '펴기' : '접기'} placement="right" mouseLeaveDelay={0}>
      <div
        onClick={() => onChange(!hidden)}
        className="cursor-pointer w-fit m-2"
        role="presentation"
      >
        <span className="text-lg font-bold">
          검색 &nbsp;
          {hidden ? (
            <CaretDownOutlined style={{ color: '#FB6921' }} />
          ) : (
            <CaretUpOutlined style={{ color: '#FB6921' }} />
          )}
        </span>
      </div>
    </Tooltip>
  );
}

function Search({ children }) {
  const [hidden, setHidden] = useState(false);

  const handleChange = (value) => {
    setHidden(value);
  };

  return (
    <>
      <SearchTitle hidden={hidden} onChange={handleChange} />
      {!hidden && (
        <>
          <Row className="search-forms">
            <div className="flex flex-row flew-wrap w-full">{children}</div>
          </Row>
          <Row className="mt-5 justify-end" key="button-area">
            <Space size="small">
              <Button type="primary" htmlType="submit">
                검색
              </Button>
            </Space>
          </Row>
        </>
      )}
    </>
  );
}

export default Object.assign(Search, {
  Title: SearchTitle,
  Item: SearchItem,
});
