import { useState } from 'react';
import { useUnmount } from 'ahooks';
import classNames from 'classnames';

import useConsultationStore from '@store/useConsultationStore';

import useConsultationDetail from '@hooks/consultation/useConsultationDetail';
import DescriptionMember from './components/DescriptionMember';
import DescriptionNonMember from './components/DescriptionNonMember';
import ConsultationDrawer from './components/ConsultationDrawer';

function ConsultationPageDetail() {
  const { nonMemberOrOnlyStudent } = useConsultationDetail();
  const { setCurrentMember, setMemberData, setNonMemberOrOnlyStudent } = useConsultationStore(
    (state) => state,
  );

  const [isDrawerOpen, setDrawerOpen] = useState(true);

  // 페이지 이동 시 store 삭제
  useUnmount(() => {
    setMemberData({});
    setCurrentMember({});
    setNonMemberOrOnlyStudent(false);
  });

  return (
    <>
      <ConsultationDrawer open={isDrawerOpen} handleOpen={setDrawerOpen} />
      <div className="flex flex-row w-full min-w-[1028px]">
        <div className={classNames(isDrawerOpen ? `w-[calc(100%-530px)]` : `w-[calc(100%-83px)]`)}>
          {nonMemberOrOnlyStudent ? <DescriptionNonMember /> : <DescriptionMember />}
        </div>
      </div>
    </>
  );
}

export default ConsultationPageDetail;
