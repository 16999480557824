import { useMemo } from 'react';
import { Tabs } from 'antd';

import { MEMBER_TYPE_CD } from '@constants/consultation';
import useConsultationStore from '@store/useConsultationStore';

import TabPaymentHistory from './tabs/TabPaymentHistory';
import TabConsultationHistory from './tabs/TabConsultationHistory';
import TabFamliy from './tabs/TabFamliy';

function MemberDetail() {
  const currentMember = useConsultationStore((state) => state.currentMember);

  const tabs = useMemo(
    () => [
      {
        label: '상담내역',
        key: 'cs-history',
        children: <TabConsultationHistory />,
      },
      ...(currentMember?.memberTypes?.some((type) => type.memberTypeCd === MEMBER_TYPE_CD.PARENTS)
        ? [
            {
              label: '가족',
              key: 'family',
              children: <TabFamliy />,
            },
          ]
        : []),
      {
        label: '결제내역',
        key: 'payment-history',
        children: <TabPaymentHistory />,
      },
      // {
      //   label: '배송내역',
      //   key: 'delivery-history',
      //   children: <TabDeliveryHistory />,
      // },
    ],
    [currentMember],
  );

  return (
    <div className="h-auto">
      <div className="my-3">
        <span className="font-semibold text-base">부가정보</span>
      </div>
      <Tabs defaultActiveKey="1" items={tabs} />
    </div>
  );
}

export default MemberDetail;
