import { useOverlayContext } from '@context/OverlayContext';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { api } from '@utils/Api';

/**
 * 상담예약 > 미완료 상담예약 요청 목록 조회
 */
export const useGetConsultReservations = (params) =>
  useQuery(['external/v1/consult-reservations', params], () =>
    api.get('/external/v1/consult-reservations', { params }),
  );

/**
 * 상담예약 > 상담예약 예외 완료처리 (다중 / 실제 통화 없는 예외 처리)
 */
export const usePostConsultReservationsException = () => {
  const queryClient = useQueryClient();
  const { openAlert, openSuccess } = useOverlayContext();

  const onSuccess = () => {
    queryClient.invalidateQueries('external/v1/consult-reservations');
    openSuccess('예외 완료처리 되었습니다.');
  };

  const onError = (error) => {
    openAlert(error.response?.data?.message ?? '예외 완료처리에 실패했습니다.');
  };

  const mutation = useMutation(
    (consultReservationIds) =>
      api.post('/external/v1/consult-reservations', {
        consultReservationIds,
      }),
    { onSuccess, onError },
  );

  const requestConsultReservationsComplete = (consultReservationIds) => {
    mutation.mutate(consultReservationIds);
  };

  return requestConsultReservationsComplete;
};

/**
 * 상담예약 > 상담예약 예외 완료처리 (단일 / 실제 통화 후 완료 처리)
 */
export const usePostConsultReservationsComplete = (onCompleteSuccess) => {
  const queryClient = useQueryClient();
  const { openAlert } = useOverlayContext();

  const onSuccess = () => {
    queryClient.invalidateQueries('external/v1/consult-reservations');
    onCompleteSuccess?.();
  };

  const onError = (error) => {
    openAlert(error.response?.data?.message ?? '상담 완료처리에 실패했습니다.');
  };

  const { mutate } = useMutation(
    (consultReservationId) => api.post(`/external/v1/consult-reservations/${consultReservationId}`),
    { onSuccess, onError },
  );

  return mutate;
};
