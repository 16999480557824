import { Drawer } from 'antd';

import ConsultationForm from './ConsultationForm';

function ConsultationDrawer({ open, handleOpen }) {
  return (
    <Drawer
      placement="right"
      open
      mask={false}
      width={open ? 530 : 83}
      headerStyle={{ display: 'none' }}
      bodyStyle={{
        padding: 0,
        marginTop: '120px',
        marginRight: '20px',
        borderRadius: '5px 0 0 0 ',
        background: 'white',
      }}
      contentWrapperStyle={{ boxShadow: 'none' }}
      style={{ background: 'none' }}
      className="register-consultation-detail-drawer-wrapper"
      zIndex={999}
    >
      <div className="flex flex-row min-h-full border-2 border-primary">
        <div
          className="bg-primary text-white flex items-center w-[60px]"
          onClick={() => handleOpen((prev) => !prev)}
          role="presentation"
        >
          <div className="w-[35px] mx-4 text-center text-sm cursor-pointer">
            이력 등록 {open ? '닫기' : '열기'}
          </div>
        </div>
        {open && (
          <div className="m-5 w-full">
            <ConsultationForm />
          </div>
        )}
      </div>
    </Drawer>
  );
}

export default ConsultationDrawer;
