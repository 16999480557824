import { Table, Space, Button, Modal } from 'antd';
import { PlayCircleOutlined, DownloadOutlined } from '@ant-design/icons';

import { CALL_TYPE, ROLE_HELP_MASTER } from '@constants/consultation';
import { toDateFormat, toPhoneNumberFormat } from '@helper/formatHelper';
import useCodeStore, { CODE_KEY } from '@store/useCodeStore';
import { useRef, useState } from 'react';
import { useUserRolesStore } from '@store/useAccountStore';
import { useGetAgentFile } from '@api/record';
import useConsultationDetail from '@hooks/consultation/useConsultationDetail';
import CopyableText from '@components/common/CopyableText';

/**
 *
 * 회원 및 비회원 상담 내역 상세 Table
 */
function TabConsultationHistory() {
  const [page, setPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentAudio, setCurrentAudio] = useState(null);
  const audioRef = useRef(null);

  const userRoles = useUserRolesStore((state) => state.userRoles);

  const isAdmin = userRoles?.includes(ROLE_HELP_MASTER);

  const { consultation } = useConsultationDetail({
    page,
  });

  const { getRecordFile } = useGetAgentFile();

  const { [CODE_KEY.CONSULTATION_CATEGORY]: consultCategories } = useCodeStore(
    (state) => state.code,
  );

  const handleSetPage = (no) => {
    setPage(no);
  };

  const handlePlay = async (fiileKey) => {
    try {
      const { streamingUrl } = await getRecordFile(fiileKey);
      setCurrentAudio(streamingUrl);
      setIsModalOpen(true);
    } catch (error) {
      Modal.error({
        title: '오류',
        content: '녹취 파일을 재생할 수 없습니다.',
      });
    }
  };

  const handleModalClose = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
    setIsModalOpen(false);
    setCurrentAudio(null);
  };

  const handleDownload = async (fiileKey) => {
    try {
      const { downloadUrl } = await getRecordFile(fiileKey);
      const link = document.createElement('a');
      link.href = downloadUrl;
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      Modal.error({
        title: '오류',
        content: '녹취 파일을 다운로드할 수 없습니다.',
      });
    }
  };

  const columns = [
    {
      title: '카테고리',
      dataIndex: 'category',
      render: (category) => consultCategories?.[category] || '-',
    },
    { title: '회원명', dataIndex: 'customerName', width: 80 },
    {
      title: '전화번호',
      dataIndex: 'customerPhone',
      render: (customerPhone) => <CopyableText text={toPhoneNumberFormat(customerPhone)} />,
    },
    { title: '내용', dataIndex: 'content', width: 400 },
    {
      title: '날짜',
      dataIndex: 'createdDate',
      render: (createdDate) => toDateFormat(createdDate),
    },
    { title: '상담사', dataIndex: 'agentName' },
    {
      title: '수신/발신',
      dataIndex: 'callType',
      render: (callType) => (callType ? CALL_TYPE[callType]?.LABEL : '-'),
    },
    {
      title: '녹취파일',
      dataIndex: 'fileKey',
      render: (fileKey) =>
        fileKey && isAdmin ? (
          <Space>
            <Button type="text" icon={<PlayCircleOutlined />} onClick={() => handlePlay(fileKey)} />
            <Button
              type="text"
              icon={<DownloadOutlined />}
              onClick={() => handleDownload(fileKey)}
            />
          </Space>
        ) : (
          '-'
        ),
    },
  ];

  return (
    <>
      <Table
        rowKey="createdDate"
        dataSource={consultation?.list || []}
        columns={columns}
        pagination={{
          showSizeChanger: false,
          total: consultation?.total || 0,
          current: consultation?.pageNum || 1,
          onChange: (current) => handleSetPage(current),
        }}
      />

      <Modal
        title="녹취파일 재생"
        open={isModalOpen}
        onCancel={handleModalClose}
        footer={null}
        width={400}
      >
        <div style={{ padding: '20px' }}>
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <audio
            ref={audioRef}
            src={currentAudio}
            controls
            autoPlay
            onEnded={handleModalClose}
            style={{ width: '100%' }}
          >
            브라우저가 오디오 재생을 지원하지 않습니다.
          </audio>
        </div>
      </Modal>
    </>
  );
}

export default TabConsultationHistory;
