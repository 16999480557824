import { createContext, useContext, useEffect } from 'react';
import { shallow } from 'zustand/shallow';
import { useMatch } from 'react-router-dom';
import { usePrevious } from 'ahooks';
import dayjs from 'dayjs';

import useCallSocket from '@feature/cti/hooks/useCallSocket';
import { useCallSocketStore } from '@feature/cti/store/useCallSocketStore';
import { AGENT_SOCKET_STATUS, CALL_EVENT, QUEUE_REASON } from '@feature/cti/data/constants';
import { callSocketActions } from '@feature/cti/actions/callSocketActions';
import { useMonitorSocketStore } from '@feature/cti/store/useMonitorSocketStore';
import useMonitorSocket from '@feature/cti/hooks/useMonitorSocket';
import { ModalCallNotification } from '@components/overlay';
import useAccountStore from '@store/useAccountStore';
import storage from '@utils/Storage';
import { connectSockets } from '@feature/cti/utils/Socket';

import { usePatchConsultRecordSave } from '@api/record';
import { CALL_TYPE } from '@constants/consultation';
import { useOverlayContext } from './OverlayContext';

const CallerContext = createContext({
  isOnCall: false,
});

function CallerProvider({ children }) {
  const isLoginPage = useMatch('/login');
  const { initCallSocket } = useCallSocket();
  const { initMonitorSocket } = useMonitorSocket();
  const { openModal, closeModal } = useOverlayContext();
  const [{ status, connected }, latestAgentCallEvent, error, call] = useCallSocketStore(
    (state) => [state.queue, state.latestAgentCallEvent, state.error, state.call],
    shallow,
  );
  const [baseDate, clear] = useMonitorSocketStore(
    (state) => [state.baseDate, state.clear],
    shallow,
  );
  const agentNumber = useAccountStore((state) => state.agentNumber);
  const prevAgentNumber = usePrevious(agentNumber);
  const { requestConsultRecordSave, requestConsultRecordEnd } = usePatchConsultRecordSave();

  const isOnCall = useCallSocketStore((state) =>
    [CALL_EVENT.RINGING, CALL_EVENT.ANSWER].includes(state.latestAgentCallEvent),
  );

  // 각 소켓 초기 세팅
  useEffect(() => {
    if (storage.hasSession() && !isLoginPage) {
      initCallSocket();
    }
  }, [initCallSocket, isLoginPage]);

  useEffect(() => {
    if (agentNumber) {
      initMonitorSocket();
    }
  }, [initMonitorSocket, agentNumber]);

  // 하루 단위로 monitor socket 초기화
  useEffect(() => {
    if (dayjs().isAfter(dayjs(baseDate), 'day')) {
      useMonitorSocketStore.persist.clearStorage();
      clear();
    }
  }, [baseDate, clear]);

  // 에이전트 로그인
  useEffect(() => {
    if (prevAgentNumber === '' && agentNumber !== '') callSocketActions.agentLogin(agentNumber);
  }, [agentNumber, prevAgentNumber]);

  // 큐 로그인, 큐 로그인 후 통화대기
  useEffect(() => {
    if (connected === false && status === AGENT_SOCKET_STATUS.READY_TO_QUEUE_CONNECT) {
      return callSocketActions.qLogin();
    }
    if (connected && status === AGENT_SOCKET_STATUS.QUEUE_CONNECTED) {
      callSocketActions.qPause(QUEUE_REASON.PAUSE.value);
    }
  }, [status, connected]);

  useEffect(() => {
    if (error.value !== 0) alert(error.label);
  }, [error]);

  // TODO: 전화 이벤트 관련 처리
  useEffect(() => {
    if (!latestAgentCallEvent) return;

    if (latestAgentCallEvent) {
      switch (latestAgentCallEvent) {
        case CALL_EVENT.RINGING:
          if (call.eventType !== CALL_TYPE.OUTBOUND.EVENT) {
            // 전화 걸기 시에는 전화 알림 모달을 띄우지 않음
            openModal(ModalCallNotification);
          }
          break;
        case CALL_EVENT.ANSWER:
          requestConsultRecordSave();
          break;
        case CALL_EVENT.HANGUP:
          callSocketActions.qPause(QUEUE_REASON.AFTER.value);
          closeModal();
          requestConsultRecordEnd();
          break;
        default:
          break;
      }
    }
  }, [
    call.eventType,
    closeModal,
    latestAgentCallEvent,
    openModal,
    requestConsultRecordEnd,
    requestConsultRecordSave,
  ]);

  useEffect(() => {
    const event = () => {
      if (document.visibilityState === 'visible') {
        connectSockets();
      }
    };
    document.addEventListener('visibilitychange', event);

    return () => {
      document.removeEventListener('visibilitychange', event);
    };
  }, []);

  return <CallerContext.Provider value={{ isOnCall }}>{children}</CallerContext.Provider>;
}

function useCallerContext() {
  return useContext(CallerContext);
}

export { CallerProvider, useCallerContext };
