import { useQuery } from '@tanstack/react-query';

import { api, logiApi } from '@utils/Api';

/**
 * 상담 분류 코드 조회
 */
export const useGetConsultationCategory = () =>
  useQuery(
    ['/external/v1/customer-consults/categories/hierarchy'],
    () => api.get('/external/v1/customer-consults/categories/hierarchy'),
    { enabled: false },
  );

/**
 * 배송 상태 코드 조회
 */
export const useGetDeliveryStatus = () =>
  useQuery(
    ['/external/v1/common/groups/DELIVERY_ORDER/subGroups/STATUS'],
    () => logiApi.get('/external/v1/common/groups/DELIVERY_ORDER/subGroups/STATUS'),
    { enabled: false },
  );
