import { Col, Form, Row, Input, Button, Radio } from 'antd';
import classnames from 'classnames';

import { HELP_DESK_TEXT_LOGO, HELP_DESK_LOGIN_PAGE_SIDE_IMAGE } from '@assets';
import { useAuthContext } from '@context/AuthContext';
import { WORKPLACE_OPTIONS } from '@constants/workplace';
import { timeFormat } from '@helper/timeHelper';
import useTimer from '@hooks/useTimer';

// 인증 유효시간 3분
const VALID_TIME = 180;

function LoginPage() {
  const { login, isSendToken, sendToken } = useAuthContext();

  // 인증 유효시간
  const { timer, startTimer, cleanUp } = useTimer(VALID_TIME);

  const [form] = Form.useForm();

  const handleLogin = (formValue) => {
    login(formValue);
  };

  const handleSendToken = () => {
    // 인증번호 발송 후 타이머 시작
    cleanUp();
    startTimer();
    sendToken(form.getFieldsValue());
  };

  return (
    <Form form={form} onFinish={handleLogin} layout="vertical">
      <Row
        style={{
          boxShadow: '0 25px 50px -12px rgb(0 0 0 / 0.25)',
          minWidth: '650px',
        }}
        justify="center"
      >
        <Col
          className={classnames('w-[430px] px-[100px]', !isSendToken ? 'h-[400px]' : 'h-[460px]')}
        >
          <Row className="my-6" justify="center" align="middle">
            <Col>
              <img src={HELP_DESK_TEXT_LOGO} alt="텍스트 로고" />
            </Col>
          </Row>
          <Row justify="center">
            <Col span={24}>
              <Form.Item
                className="font-bold"
                name="userId"
                label="아이디"
                colon={false}
                rules={[{ required: true, message: '아이디를 입력하세요!' }]}
              >
                <Input
                  className="border-t-0 border-r-0 border-l-0 rounded-none focus:shadow-none"
                  placeholder="아이디"
                  type="text"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="center">
            <Col span={24}>
              <Form.Item
                className="font-bold"
                name="userPw"
                label="비밀번호"
                colon={false}
                rules={[{ required: true, message: '비밀번호를 입력하세요!' }]}
              >
                <Input
                  className="border-t-0 border-r-0 border-l-0 rounded-none focus:shadow-none"
                  placeholder="비밀번호"
                  type="password"
                />
              </Form.Item>
            </Col>
          </Row>
          {isSendToken && (
            <Row justify="center">
              <Col span={24}>
                <Form.Item
                  className="font-bold"
                  name="authKey"
                  label="인증번호"
                  help={`유효시간: ${timeFormat(timer)}초`}
                  colon={false}
                  rules={[{ required: true, message: '인증번호를 입력하세요!' }]}
                >
                  <Input
                    className="border-t-0 border-r-0 border-l-0 rounded-none focus:shadow-none"
                    placeholder="인증번호"
                    type="text"
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
          <Row justify="center">
            <Col span={24}>
              <Form.Item noStyle initialValue={WORKPLACE_OPTIONS.OFFICE} name="workplace">
                <Radio.Group className="flex justify-center my-2">
                  <Radio value={WORKPLACE_OPTIONS.OFFICE}>출근</Radio>
                  <Radio value={WORKPLACE_OPTIONS.REMOTE}>재택</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row justify="center">
            <Col span={24}>
              {!isSendToken && (
                <Form.Item noStyle>
                  <Button
                    className="w-full my-2"
                    htmlType="button"
                    size="large"
                    onClick={handleSendToken}
                  >
                    인증번호 전송
                  </Button>
                </Form.Item>
              )}
              {isSendToken && (
                <Form.Item noStyle>
                  <Button
                    className="w-[110px] my-2"
                    htmlType="button"
                    size="large"
                    onClick={handleSendToken}
                  >
                    재전송
                  </Button>
                  <Button
                    className="w-[110px] my-2 ml-[10px]"
                    type="primary"
                    htmlType="submit"
                    size="large"
                  >
                    로그인
                  </Button>
                </Form.Item>
              )}
            </Col>
          </Row>
          {/* <Row justify="center">
            <Col span={24}>
              <Button
                className="w-full m-auto"
                onClick={() => alert('구현 예정')}
                type="text"
              >
                <span className="underline">비밀번호 재설정</span>
              </Button>
            </Col>
          </Row> */}
        </Col>
        <Col>
          <img
            src={HELP_DESK_LOGIN_PAGE_SIDE_IMAGE}
            alt="로그인 페이지 사이드 이미지"
            className="h-full"
          />
        </Col>
      </Row>
      <Row className="mt-8" justify="center" align="middle">
        <Col>&copy; (주) 단비교육</Col>
      </Row>
    </Form>
  );
}

export default LoginPage;
