import { FloatButton, Tag, Tooltip } from 'antd';
import classnames from 'classnames';
import '@assets/css/ui.waitingQueue.css';

const BASE_WIDTH = 120;
const TOOLTIP_WIDTH = 43;

function WaitingQueue({ readyAgents, agentNumber }) {
  return (
    <FloatButton
      shape="square"
      description={
        <div className="flex flex-row items-center">
          <div className={classnames('font-bold', readyAgents.length > 0 && 'mr-[10px]')}>
            수신 대기 순서
          </div>
          {readyAgents.map((readyAgent) => (
            <Tooltip
              key={readyAgent.key}
              placement="bottom"
              title={readyAgent.readyTime}
              mouseLeaveDelay={0}
              zIndex={9999}
            >
              <Tag
                className={`${
                  readyAgent.agent === agentNumber ? 'bg-secondary' : 'bg-primary'
                } text-white border-0`}
              >
                <span>{readyAgent.agent}</span>
              </Tag>
            </Tooltip>
          ))}
        </div>
      }
      className="h-[40px] top-[70px] z-[1000]"
      style={{
        width: `${BASE_WIDTH + TOOLTIP_WIDTH * readyAgents.length}px`,
        right: '630px',
      }}
    />
  );
}
export default WaitingQueue;
