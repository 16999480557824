import { Button, Table } from 'antd';

import { toDateFormat, toPhoneNumberFormat } from '@helper/formatHelper';
import useConsultationStore from '@store/useConsultationStore';
import { useCallback } from 'react';
import CopyableText from '@components/common/CopyableText';

function TabFamliy() {
  const currentMember = useConsultationStore((state) => state.currentMember);
  const { family } = currentMember;

  const goDetail = useCallback(
    (_family) => {
      // 본인이 학생인 경우(클릭한 가족이 학부모id가 있는 경우) 자신(학생)의 CAS 상세 페이지 이동
      if (_family?.parentId) {
        window.open(
          `${process.env.REACT_APP_CAS_URL}/account/student/${currentMember?.memberId}`,
          '_blank',
        );
      }
      // 본인이 학부모인 경우(클릭한 가족이 studentId가 있는 경우) 각 학생의 CAS 상세 페이지 이동
      if (_family?.studentId) {
        window.open(
          `${process.env.REACT_APP_CAS_URL}/account/student/${_family.studentId}`,
          '_blank',
        );
      }
    },
    [currentMember?.memberId],
  );

  const columns = [
    { title: '회원 ID', dataIndex: 'memberId' },
    {
      title: '회원 이름',
      dataIndex: 'memberName',
      render: (memberName, _family) => (
        <Button className="p-0" type="link" onClick={() => goDetail(_family)}>
          {memberName}
        </Button>
      ),
    },
    {
      title: '구분',
      dataIndex: ['memberTypes', '0', 'memberTypeNm'],
      render: (_, row) =>
        `${row.memberTypes[0].memberTypeNm}${
          row.courseNm ? ` / ${row.courseNm} ${row.gradeNm}` : ''
        }`,
    },
    {
      title: '상태',
      dataIndex: 'memberGradeNm',
      render: (_, row) => `${row.memberGradeNm} / ${row.memberStateNm}`,
    },
    {
      title: '전화번호',
      dataIndex: 'mobile',
      render: (mobile) => <CopyableText text={toPhoneNumberFormat(mobile)} />,
    },
    {
      title: '가입날짜',
      dataIndex: 'accountCreatedAt',
      render: (date) => toDateFormat(date, 'YYYY-MM-DD'),
    },
  ];

  return (
    <Table
      rowKey="memberId"
      total={family?.length || 0}
      dataSource={family || []}
      columns={columns}
      pagination={false}
    />
  );
}

export default TabFamliy;
