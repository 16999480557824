import { FloatButton } from 'antd';

import { isProduction } from '@utils/systemPhaseUtils';
import { useMonitorSocketStore } from '../store/useMonitorSocketStore';

const POSITION_RIGHT = 35;
const BUTTON_GAP = 85;
const BADGE_OFFSET = [4, -4];

function CallCategory({ label, field, index }) {
  const { catsQueue, catsHomeQueue, mergeQueue } = useMonitorSocketStore((state) => ({
    catsQueue: state.catsQueue,
    catsHomeQueue: state.catsHomeQueue,
    mergeQueue: state.mergeQueue,
  }));

  const catsValue = catsQueue?.[field] || 0;
  const catsHomeValue = catsHomeQueue?.[field] || 0;
  const badgeLabel = isProduction()
    ? `${catsHomeValue} · ${catsValue}`
    : `${mergeQueue?.[field] || 0}`;

  return (
    <FloatButton
      shape="square"
      description={label}
      badge={{
        count: badgeLabel,
        style: { cursor: 'default' },
        offset: BADGE_OFFSET,
        showZero: true,
      }}
      className="w-[70px] h-[40px] top-[70px] z-[1000]"
      style={{
        right: POSITION_RIGHT + BUTTON_GAP * index,
        cursor: 'default',
        pointerEvents: 'none',
      }}
    />
  );
}

export default CallCategory;
